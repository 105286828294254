<template>
  <div class="flex flex-col gap-1">
    <div v-if="props.label" class="flex row mx-1">
      <label
        class="flex-1 text-tertiary text-sm font-semibold"
        v-text="props.label"
      />
    </div>
    <div
      class="flex bg-primary-10 text-sm text-secondary shadow-small rounded-lg border border-secondary-40 focus-within:border-tertiary-80"
    >
      <AvvSelect
        class="flex flex-grow"
        v-bind="$attrs"
        :placeholder="props.placeholder"
        :readonly="props.readonly"
        :value="props.modelValue"
        @select="
          (option) => emit(`update:modelValue`, option.getAttribute('value'))
        "
        data-span-class="w-full px-3 py-2"
      >
        <slot name="span"></slot>
        <div
          class="menu flex w-full p-2.5 box-border column gap-2 bg-primary-10 border border-tertiary-80 rounded-lg shadow-small mt-0.5 overflow-hidden"
        >
          <slot></slot>
        </div>
        <button
          type="button"
          class="rounded-r-lg text-secondary-60 hover:text-secondary cursor-pointer select-none material-icons"
        >
          arrow_drop_down
        </button>
      </AvvSelect>
    </div>
    <span
      v-if="props.helpText"
      class="text-xs italic text-secondary-80 mx-1"
      v-text="props.helpText"
    />
  </div>
</template>
<script lang="ts" setup>
const AvvSelect = window.AvvSelect
const props = defineProps<{
  label: string
  helpText?: string
  modelValue: any
  placeholder?: any
  readonly?: boolean
}>()

const emit = defineEmits(['update:modelValue'])
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'DSelect' })
</script>
